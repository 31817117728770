















































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { DateRangeFilter, DateRangeModel } from "@/core/models";
import { DateFields, FieldLabels } from "@/core/services";
import FilterBase from "./FilterBase.vue";
import moment from "moment";
export const events = {
  Pick: "Pick",
};

@Component({
  components: {},
})
export default class FilterDatesCpt extends FilterBase {
  @Prop() model: DateRangeFilter;
  @Prop() className: string;
  @Prop({ default: "dateUpdated" }) dateFilter: string;
  @Prop({ default: "Date Label" }) dateLabel: string;
  @Prop({ default: false }) onlyPastDates: boolean;
  @Prop({ default: false }) readonly: boolean;
  @Prop({ default: true }) showButton: boolean;
  displayFormat: string = "dd-MM-yyyy";
  dateFormat: string = "yyyy-MM-dd";
  momentDateFormat: string = "yyyy-MM-DD";
  internalModel = {
    relative: "",
    start: "",
    end: "",
  };
  DateFields = DateFields;
  FieldLabels = FieldLabels;
  options = [
    { label: "Yesterday", value: "-1d", isPast: true },
    { label: "In the last Week", value: "-7d", isPast: true },
    { label: "In the last Month", value: "-1", isPast: true },
    { label: "In the last 2 Months", value: "-2", isPast: true },
    { label: "In the last 3 Months", value: "-3", isPast: true },
    { label: "In the last 6 Months", value: "-6", isPast: true },
    { label: "In the next Week", value: "7d", isPast: false },
    { label: "In the next Month", value: "1", isPast: false },
    { label: "In the next 2 Months", value: "2", isPast: false },
    { label: "In the next 3 Months", value: "3", isPast: false },
    { label: "In the next 6 Months", value: "6", isPast: false },
    { label: "Between Dates", value: "bt", isPast: true },
  ];

  @Watch("internalModel", { deep: true })
  onInternalModelChange() {
    if (!this.showButton) {
      this.applyFilter();
    }
  }

  get optionPickerBefore() {
    return {
      disabledDate: (date: Date) => (this.onlyPastDates ? date >= new Date() : false),
    };
  }
  get optionPickerAfter() {
    return {
      disabledDate: (date: Date) => (this.onlyPastDates ? date >= new Date() : false),
    };
  }

  created() {
    this.setupEvents(this.dateFilter);
    this.bindModelToInternal();
  }

  destroyed() {
    this.destroyEvents();
  }

  get dateSelected() {
    return this.model.value || this.model.startDate || this.model.endDate;
  }

  get buttonStatus() {
    return this.dateSelected ? `primary` : "default";
  }
  get areDatesValid() {
    const areBothDates = !!this.internalModel.start && !!this.internalModel.end;
    if (!areBothDates) {
      return true;
    }
    const dateStart = moment(this.internalModel.start, this.momentDateFormat);
    const dateEnd = moment(this.internalModel.end, this.momentDateFormat);
    if (!dateStart.isValid || !dateEnd.isValid) {
      return true;
    }
    return dateStart.isBefore(dateEnd);
  }
  get isValid() {
    const areDates = !!(this.internalModel.start || this.internalModel.end);
    const areBothDates = !!this.internalModel.start && !!this.internalModel.end;
    const isBt = this.internalModel.relative === "bt";
    const isRelative = this.internalModel.relative !== "bt" && this.internalModel.relative !== "";
    const isClear = !this.internalModel.relative && !this.internalModel.start && !this.internalModel.end;
    return isRelative || (isBt && areDates && !areBothDates) || (isBt && areBothDates && this.areDatesValid) || isClear;
  }

  selectionChanged(ev: string) {
    if (ev !== "bt") {
      this.internalModel.start = "";
      this.internalModel.end = "";
    }
  }

  get buttonText() {
    if (this.dateSelected) {
      if (this.model.value) {
        let v = this.options.find((x) => x.value === this.model.value);
        if (!v) {
          v = { label: "error", value: "", isPast: false };
        }
        return `${this.dateLabel} ${v.label}`;
      }
      if (this.model.startDate) {
        if (this.model.endDate) {
          return `${this.dateLabel} between ${this.model.startDate} and ${this.model.endDate}`;
        }
        return `${this.dateLabel} after ${this.model.startDate}`;
      }
      return `${this.dateLabel} before ${this.model.endDate}`;
    } else {
      return this.dateLabel;
    }
  }

  toggleDialog() {
    this.bindModelToInternal();
    this.$nextTick(() => {
      this.toggle();
    });
  }

  bindModelToInternal() {
    // update internal model
    this.internalModel.start = this.model.startDate;
    this.internalModel.end = this.model.endDate;
    this.internalModel.relative = this.model.value || "";
    if (this.internalModel.start || this.internalModel.end) {
      this.internalModel.relative = "bt";
    }
  }

  clear() {
    this.internalModel.relative = "";
    this.internalModel.start = "";
    this.internalModel.end = "";
    this.applyFilter();
  }
  dismiss() {
    this.toggle();
  }
  applyFilter() {
    this.close();
    const range: DateRangeFilter = {
      filterName: this.dateFilter,
      startDate: this.internalModel.start,
      endDate: this.internalModel.end,
      value: this.internalModel.relative !== "bt" ? this.internalModel.relative : "",
    };
    this.trackEvent("FilterApplied", { category: "Search Results", label: this.dateFilter });
    this.$emit("updated", range);
  }
}
