





























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CompanyService } from "@/core/services";
import { SectorIconCpt, WatchedButton } from "@/core/components";
import AppVue from "@/AppVue.vue";
import { SearchResultContainer, CompanySearchResultModel } from "@/core/models";
import { WatcherService } from "@/core/services";
import { roles as Roles, roleFlag } from "@/core/constants";

@Component({
  components: {
    SectorIconCpt,
    WatchedButton,
  },
})
export default class CompanySearchResultItemCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: new SearchResultContainer() }) model: SearchResultContainer<CompanySearchResultModel>;
  @Prop({ default: false }) selected: boolean;
  @Prop({ default: false }) isWatched: boolean;
  @Prop({ default: "" }) searchId: string;
  @Prop({ default: 0 }) rank: number;
  internalIsWatched: boolean = false;

  roles = { icons: roleFlag, ...Roles };
  get isLoading() {
    return this.loading;
  }

  async toggleWatchList(model: SearchResultContainer<CompanySearchResultModel>) {
    const companyId = Number(model.document.companyId);
    if (model.document.isWatched) {
      await WatcherService.unwatchCompany(companyId);
      this.$nextTick(() => {
        this.$emit("unwatchedCompany", model);
        model.document.isWatched = false;
        this.internalIsWatched = model.document.isWatched;
        this.$notify({
          title: "Tracking",
          message: `No longer tracking ${model.document.name}`,
          type: "success",
        });
      });
    } else {
      await WatcherService.watchCompany(model.document);
      this.$emit("watchedCompany", model);
      model.document.isWatched = true;
      this.internalIsWatched = model.document.isWatched;

      this.$notify({
        title: "Tracking",
        message: `Now tracking ${model.document.name}`,
        type: "success",
      });
    }
  }

  selectItem(item: SearchResultContainer<CompanySearchResultModel>) {
    this.trackEvent("PeekCompany", {
      category: "Search Results",
      label: "PeekCompany",
      value: item.document.companyId,
    });
    this.trackEvent("Click", {
      SearchServiceName: "Company search",
      SearchId: this.searchId,
      ClickedDocId: item.document.companyId,
      Rank: this.rank,
    });
    CompanyService.peek(item.document.companyId);

    this.$emit("selectItem", item);
  }

  created() {
    this.$eventHub.$on("MarkerClicked", this.markerClick);
    this.$eventHub.$on("ScrollToResult", this.scrollToResult);
  }
  destroyed() {
    this.$eventHub.$off("MarkerClicked", this.markerClick);
    this.$eventHub.$on("ScrollToResult", this.scrollToResult);
  }

  scrollToResult(focusElement: any) {
    const pid = focusElement.resultId;
    if (this.model.document.companyId === pid) {
      this.$nextTick(() => {
        this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
      });
    }
  }
  markerClick(item: any) {
    const pid = item.data.companyId;
    if (this.model.document.companyId === pid) {
      this.$nextTick(() => {
        this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
      });
      this.selectItem(this.model);
    }
  }

  async mounted() {
    this.internalIsWatched = this.model.document.isWatched;
  }
}
