
















































import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterMaterialsCpt extends FilterBase {
  @Prop({ default: [] }) model: string[];
  @Prop() materials: any[];
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;
  $refs: {
    tree: HTMLFormElement;
  };

  selectedMaterials: string[] = [];
  filterText: string = "";
  defaultProps = {
    children: "children",
    label: "name",
  };

  @Watch("filterText") filterTextUpdate(val: any) {
    this.$refs.tree.filter(val);
  }

  get treeData() {
    if (!this.materials) {
      return [];
    }
    const root = this.materials.filter((x) => x.parentId === null);
    root.forEach((b) => (b.children = this.materials.filter((m) => m.parentId === b.id)));
    return root;
  }

  created() {
    this.setupEvents("Materials");
  }
  mounted() {}
  destroyed() {
    this.destroyEvents();
  }
  filterNode(value: string, data: any) {
    if (!value) {
      return true;
    }
    return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }
  toggleDialog() {
    this.toggle();
    this.selectedMaterials = this.model;
    this.$refs.tree.setCheckedKeys(this.selectedMaterials, true);
  }

  clear() {
    this.filterText = "";
    this.selectedMaterials.splice(0, this.selectedMaterials.length);
    this.$refs.tree.setCheckedKeys(this.selectedMaterials, true);
    this.applyFilter();
  }
  dismiss() {
    this.toggle();
  }
  applyFilter() {
    this.close();
    this.selectedMaterials = this.$refs.tree.getCheckedKeys(true);
    this.trackEvent("FilterApplied", { category: "Search Results", label: this.filterName });
    this.$emit("updated", this.selectedMaterials);
  }

  get buttonText() {
    if (!this.model.length) {
      return "Materials";
    }
    if (this.model.length === 1) {
      if (this.$isMobile()) {
        return `Materials: ${this.model[0]}`;
      }
      return this.model[0];
    } else {
      if (this.$isMobile()) {
        return `Materials (${this.model.length})`;
      }
      return `${this.model.length} Materials`;
    }
  }

  get buttonType() {
    return this.model.length ? "primary" : "";
  }
}
