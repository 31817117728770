






















































































































































































































import { Component, Prop } from "vue-property-decorator";
import FilterBase from "./FilterBase.vue";
import { IMaxMinValue, IUIFilters, SearchService, TurnstoneLookupsModel } from "@/core/services";
import { DateRangeFilter } from "@/core/models";
import FilterContractStageCpt from "./FilterContractStageCpt.vue";
import FilterGenericRangeCpt from "./FilterGenericRangeCpt.vue";
import FilterSelfBuildCpt from "./FilterSelfBuildCpt.vue";
import FilterRoleCpt from "./FilterRoleCpt.vue";
import FilterDatesCpt from "./FilterDatesCpt.vue";
import FilterGenericCompareCpt from "./FilterGenericCompareCpt.vue";
import FilterBooleanCpt from "./FilterBooleanCpt.vue";
import FilterAppealStatusCpt from "./FilterAppealStatusCpt.vue";
import FilterGenericCompareIDCpt from "./FilterGenericCompareIDCpt.vue";

@Component({
  components: {
    FilterSelfBuildCpt: () => Promise.resolve(FilterSelfBuildCpt),
    FilterRoleCpt: () => Promise.resolve(FilterRoleCpt),
    FilterContractStageCpt: () => Promise.resolve(FilterContractStageCpt),
    FilterGenericRangeCpt: () => Promise.resolve(FilterGenericRangeCpt),
    FilterGenericCompareCpt: () => Promise.resolve(FilterGenericCompareCpt),
    FilterDatesCpt: () => Promise.resolve(FilterDatesCpt),
    FilterBooleanCpt: () => Promise.resolve(FilterBooleanCpt),
    FilterAppealStatusCpt: () => Promise.resolve(FilterAppealStatusCpt),
    FilterGenericCompareIDCpt: () => Promise.resolve(FilterGenericCompareIDCpt),
  },
})
export default class AdditionalFiltersCpt extends FilterBase {
  @Prop() model: IUIFilters;
  @Prop() lookups: TurnstoneLookupsModel;
  @Prop() isSelfBuildEnabled: boolean;
  @Prop() isSelfBuildSub: boolean;
  @Prop() isMajorProjectsSub: boolean;
  @Prop() className: string;
  filters: IUIFilters = SearchService.defaultFilter();
  dialogVisible = false;
  showMoreFilters = false;
  accordianActive = "";

  get roleTreeData() {
    if (!this.lookups) {
      return [];
    }
    if (!this.lookups.roleList) {
      return [];
    }
    return this.lookups.roleList.map((role) => {
      return {
        id: role.roleID,
        label: role.name,
        children: role.childRoles
          ? role.childRoles.map((x: any) => {
              return {
                id: x.roleID,
                label: x.name,
              };
            })
          : null,
      };
    });
  }

  get additionalFiltersCount() {
    let filterCount = 0;
    if (this.model.applicationType.length) {
      filterCount++;
    }
    if (this.model.appealStatusList.length) {
      filterCount++;
    }
    if (this.model.constructionType.length) {
      filterCount++;
    }

    if (this.model.publicTag.length) {
      filterCount++;
    }

    if (this.model.planningAuthority.length) {
      filterCount++;
    }
    if (this.model.roles.length) {
      filterCount++;
    }
    if (this.model.fundingType.length) {
      filterCount++;
    }
    if (this.model.isFramework != "Any") {
      filterCount++;
    }

    return filterCount + this.datesSet + this.metricsSet;
  }

  get metricsSet() {
    let filterCount = 0;
    if (this.numberRangeSet(this.model.siteArea)) {
      filterCount++;
    }
    if (this.numberRangeSet(this.model.floorArea)) {
      filterCount++;
    }
    if (this.numberRangeSet(this.model.numberOfParkingSpaces)) {
      filterCount++;
    }
    if (this.numberRangeSet(this.model.numberOfStoreys)) {
      filterCount++;
    }
    if (this.numberRangeSet(this.model.numberOfUnits)) {
      filterCount++;
    }
    return filterCount;
  }

  get metricsSetInternal() {
    let filterCount = 0;
    if (this.numberRangeSet(this.filters.siteArea)) {
      filterCount++;
    }
    if (this.numberRangeSet(this.filters.floorArea)) {
      filterCount++;
    }
    if (this.numberRangeSet(this.filters.numberOfParkingSpaces)) {
      filterCount++;
    }
    if (this.numberRangeSet(this.filters.numberOfStoreys)) {
      filterCount++;
    }
    if (this.numberRangeSet(this.filters.numberOfUnits)) {
      filterCount++;
    }
    return filterCount;
  }

  get datesSet() {
    let filterCount = 0;
    if (this.dateRangeSet(this.model.tenderDeadlineDate)) {
      filterCount++;
    }
    if (this.dateRangeSet(this.model.applicationDate)) {
      filterCount++;
    }
    if (this.dateRangeSet(this.model.projectStartDate)) {
      filterCount++;
    }
    if (this.dateRangeSet(this.model.decisionDate)) {
      filterCount++;
    }
    if (this.dateRangeSet(this.model.finishDate)) {
      filterCount++;
    }
    return filterCount;
  }

  get datesSetInternal() {
    let filterCount = 0;
    if (this.dateRangeSet(this.filters.tenderDeadlineDate)) {
      filterCount++;
    }
    if (this.dateRangeSet(this.filters.applicationDate)) {
      filterCount++;
    }
    if (this.dateRangeSet(this.filters.projectStartDate)) {
      filterCount++;
    }
    if (this.dateRangeSet(this.filters.decisionDate)) {
      filterCount++;
    }
    if (this.dateRangeSet(this.filters.finishDate)) {
      filterCount++;
    }
    return filterCount;
  }

  get additionalFiltersText() {
    if (this.additionalFiltersCount > 0) {
      if (this.$isMobile()) {
        return `Additional filters (${this.additionalFiltersCount})`;
      }
      return `${this.additionalFiltersCount} Additional filters`;
    }

    return "Additional filters";
  }

  get additionalFiltersButtonType() {
    if (this.additionalFiltersCount > 0) {
      return "primary";
    }
    return "";
  }
  contextualHelp(context: string) {
    this.$eventHub.$emit("HelpRequest", context);
  }
  dateRangeSet(filter: DateRangeFilter) {
    return filter.value || filter.startDate || filter.endDate;
  }

  numberRangeSet(range: IMaxMinValue) {
    return range.max || range.min;
  }

  showAdditionalFilters() {
    this.showMoreFilters = true;
    this.filters = { ...this.model };
  }

  handleCloseFilters() {
    this.showMoreFilters = false;
  }

  rolesUpdated(selectedRoles: string[]) {
    this.filters.roles = selectedRoles;
  }
  contractStagesUpdated(selectedStages: string[]) {
    this.filters.contractStage = selectedStages;
  }
  fundingTypeUpdated(selectedTypes: string[]) {
    this.filters.fundingType = selectedTypes;
  }
  planningAuthorityUpdated(selected: string[]) {
    this.filters.planningAuthority = selected;
  }
  applicationTypeUpdated(selected: string[]) {
    this.filters.applicationType = selected;
  }
  constructionTypeUpdated(selected: string[]) {
    this.filters.constructionType = selected;
  }
  publicTagUpdated(selected: number[]) {
    this.filters.publicTag = selected;
  }
  changeDateCreated(selectedDates: DateRangeFilter) {
    this.filters.dateCreated = selectedDates;
  }
  changeTenderDeadlineDate(selectedDates: DateRangeFilter) {
    this.filters.tenderDeadlineDate = selectedDates;
  }
  changeFinishDate(selectedDates: DateRangeFilter) {
    this.filters.finishDate = selectedDates;
  }
  changeDecisionDate(selectedDates: DateRangeFilter) {
    this.filters.decisionDate = selectedDates;
  }
  changeProjectStartDate(selectedDates: DateRangeFilter) {
    this.filters.projectStartDate = selectedDates;
  }
  changeApplicationDate(selectedDates: DateRangeFilter) {
    this.filters.applicationDate = selectedDates;
  }
  selfBuildUpdated(isSelfBuild: string) {
    this.filters.isSelfBuild = isSelfBuild;
  }
  floorAreaUpdated(range: IMaxMinValue) {
    this.filters.floorArea = range;
  }
  parkingSpacesUpdated(range: IMaxMinValue) {
    this.filters.numberOfParkingSpaces = range;
  }
  siteAreaUpdated(range: IMaxMinValue) {
    this.filters.siteArea = range;
  }
  storeysUpdated(range: IMaxMinValue) {
    this.filters.numberOfStoreys = range;
  }
  unitsUpdated(range: IMaxMinValue) {
    this.filters.numberOfUnits = range;
  }
  isFrameworkUpdated(value: string) {
    this.filters.isFramework = value;
  }
  appealStatusUpdated(value: number[]) {
    this.filters.appealStatusList = value;
  }
  applyAdditionalFilters() {
    this.accordianActive = "";

    // finally, update filters, to run the search
    this.$emit("updated", this.filters);

    // close the dialog
    this.showMoreFilters = false;
  }

  created() {
    this.setupEvents("additionalFilters");
  }

  destroyed() {
    this.destroyEvents();
  }
}
