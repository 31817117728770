


























import { Component, Prop } from "vue-property-decorator";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterContractStageCpt extends FilterBase {
  @Prop({ default: [] }) model: string[];
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;
  @Prop() stages: any[];
  selectedStages: string[] = [];

  created() {
    this.setupEvents("ContractStages");
  }
  destroyed() {
    this.destroyEvents();
  }

  toggleDialog() {
    // call the base toggle method
    this.toggle();

    // actions specific to this filter
    this.selectedStages = this.model;
  }

  clear() {
    // The :key attribute in the el-checkbox-group is responsible of the refresh.
    // when the selectedStages.length changes. the checkbox group is re-rendered.
    // if you remove the :key attribute, the ui will be out of sync with the model
    this.selectedStages.splice(0, this.selectedStages.length);
    this.applyFilter();
  }
  dismiss() {
    this.toggle();
  }
  applyFilter() {
    this.close();
    this.trackEvent("FilterApplied", {
      category: "Search Results",
      label: this.filterName,
    });

    this.$emit("updated", this.selectedStages);
  }

  get buttonText() {
    if (!this.model.length) {
      return "Contract Stage";
    }
    if (this.model.length === 1) {
      return this.model[0];
    } else {
      return `${this.model.length} Contract Stages`;
    }
  }

  get buttonType() {
    return this.model.length ? "primary" : "";
  }
}
