
















































import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import FilterBase from "./FilterBase.vue";
import { Dictionary } from "vue-gtag";

export class Type {}

@Component({
  components: {},
})
export default class FilterTrackedUntrackedCpt extends FilterBase {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) readonly: boolean;
  @Prop() className: string;

  @Prop({ default: "All" }) model: string;
  selectedValue: string = "All";
  options: Dictionary<string> = {
    All: "Tracking status",
    Tracked: "Tracked by me",
    "Not Tracked": "Not tracked by me",
    Company: "Tracked by my company",
    "Company Not Tracked": "Not tracked by my company",
  };

  select(el: string) {
    this.$emit("updated", el);
  }

  toggleDialog() {
    // call the base toggle method
    this.toggle();

    // set value from model
    this.selectedValue = this.model;
  }

  created() {
    this.setupEvents("TrackingStatus");
  }

  destroyed() {
    this.destroyEvents();
  }

  clear() {
    // The :key attribute in the el-checkbox-group is responsible of the refresh.
    // when the selectedStages.length changes. the checkbox group is re-rendered.
    // if you remove the :key attribute, the ui will be out of sync with the model
    this.selectedValue = "All";
    this.applyFilter();
  }

  dismiss() {
    this.toggle();
  }

  applyFilter() {
    this.close();
    this.trackEvent("FilterApplied", {
      category: "Search Results",
      label: this.filterName,
    });

    this.$emit("updated", this.selectedValue);
  }

  get buttonText() {
    return this.options[this.model];
  }

  get buttonType() {
    return this.model !== "All" ? "primary" : "";
  }
}
