


























import { Component, Prop, Watch } from "vue-property-decorator";
import FilterBase from "./FilterBase.vue";
import { IMaxMinValue } from "@/core/services";

@Component
export default class FilterGenericRangeCpt extends FilterBase {
  @Prop() model: IMaxMinValue;
  @Prop({ default: "Label" }) label: string;
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;

  selectedMin = "";
  selectedMax = "";
  $refs: any;

  @Watch("selectedMin")
  @Watch("selectedMax")
  onRangeUpdated() {
    this.$emit("updated", { min: this.selectedMin, max: this.selectedMax });
  }

  mounted() {
    this.selectedMin = this.model.min;
    this.selectedMax = this.model.max;
  }
  dismiss() {
    this.toggle();
  }
  applyFilter() {
    this.trackEvent("FilterApplied", {
      category: "Search Results",
      label: this.filterName,
    });

    this.$emit("updated", { min: this.selectedMin, max: this.selectedMax });
  }

  clear() {
    this.selectedMin = "";
    this.selectedMax = "";
    this.applyFilter();
  }

  get buttonText() {
    return `From ${this.selectedMin} to ${this.selectedMax}`;
  }

  get buttonType() {
    return this.selectedMin !== "" || this.selectedMax !== "" ? "primary" : "";
  }
}
