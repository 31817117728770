
























































/*
  <FilterGenericCompareCpt
        :model="filters.categories"
        :lookup="lookups.categoryList"
        @updated="categoriesUpdated"
        label="name"
        field="Category"
        fields="Categories"
      />
*/

import { Component, Prop, Watch } from "vue-property-decorator";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterGenericCompareCpt extends FilterBase {
  @Prop({ default: [] }) model: string[];
  selectedValues: string[] = [];
  @Prop({ default: "name" }) label: string;
  @Prop({ default: "field" }) field: string;
  @Prop({ default: "fields" }) fields: string;
  @Prop({ default: false }) readonly: boolean;
  @Prop() className: string;
  @Prop({ default: true }) showButton: boolean;
  @Prop() lookup: any[];

  @Watch("selectedValues", { deep: true })
  onSelectedValuesChange() {
    if (!this.showButton) {
      this.$emit("updated", this.selectedValues);
    }
  }

  mounted() {
    this.selectedValues = this.model;
  }

  handleClose(done: any) {}

  clear() {
    // The :key attribute in the el-checkbox-group is responsible of the refresh.
    // when the selectedStages.length changes. the checkbox group is re-rendered.
    // if you remove the :key attribute, the ui will be out of sync with the model
    this.selectedValues.splice(0, this.selectedValues.length);
    this.applyFilter();
  }

  applyFilter() {
    this.close();
    this.trackEvent("FilterApplied", {
      category: "Search Results",
      label: this.field,
    });
    this.$emit("updated", this.selectedValues);
  }

  get buttonText() {
    if (!this.model.length) {
      return this.field;
    }
    if (this.model.length === 1) {
      return this.model[0];
    } else {
      return `${this.model.length} ${this.fields}`;
    }
  }

  get buttonType() {
    return this.model.length ? "primary" : "";
  }
}
