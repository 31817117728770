












































import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterLocationCpt extends FilterBase {
  @Prop({ default: [] }) model: string[];
  @Prop() countries: any[];
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;

  $refs: {
    tree: HTMLFormElement;
  };

  selectedCounties: string[] = [];
  defaultProps = {
    children: "children",
    label: "name",
  };

  get treeData() {
    return this.countries;
  }

  created() {
    this.setupEvents("Location");
  }

  destroyed() {
    this.destroyEvents();
  }

  toggleDialog() {
    this.toggle();
    this.selectedCounties = this.model;
    this.$refs.tree.setCheckedKeys(this.selectedCounties, true);
  }

  clear() {
    this.selectedCounties.splice(0, this.selectedCounties.length);
    this.$refs.tree.setCheckedKeys(this.selectedCounties, true);
    this.applyFilter();
  }
  dismiss() {
    this.toggle();
  }
  applyFilter() {
    this.close();
    this.selectedCounties = this.$refs.tree.getCheckedKeys(true);
    this.trackEvent("FilterApplied", { category: "Search Results", label: this.filterName });
    this.$emit("updated", this.selectedCounties);
  }

  get buttonText() {
    if (!this.model.length) {
      return "Location";
    }
    if (this.model.length === 1) {
      if (this.$isMobile()) {
        return `Location: ${this.model[0]}`;
      }
      return this.model[0];
    } else {
      if (this.$isMobile()) {
        return `Location (${this.model.length})`;
      }
      return `${this.model.length} Locations`;
    }
  }

  get buttonType() {
    return this.model.length ? "primary" : "";
  }
}
