









































































import { Component, Vue, Prop } from "vue-property-decorator";

import {
  IUIFilters,
  SearchService,
  TurnstoneLookupsModel,
  IGeoRange,
  ProfileModel,
} from "@/core/services";
import {} from "@/core/components";
import { AccountService } from "@/core/services";

import AppVue from "@/AppVue.vue";
import { DateRangeFilter } from "@/core/models";
import AdditionalFiltersCpt from "./AdditionalFiltersCpt.vue";
import { FilterGeoRangeCpt, FilterLocationCpt } from "@/modules/search";
import FilterDatesCpt from "./FilterDatesCpt.vue";
import FilterRoleCpt from "./FilterRoleCpt.vue";
import { LatLng } from "leaflet";
import FilterPolylineCpt from "./FilterPolylineCpt.vue";
import { MapEvents } from "@/modules/map/MapView.vue";

export const events = {
  FilterUpdated: "filterUpdated",
  SetDefaultFilters: "filterSet",
  ClearFilters: "clearFilters",
  ToggleMap: "toggleMap",
};
class FilterDefinition {
  key: string;
  label: string;
  visible: boolean;
  canBeHidden: boolean;
}
class VisibleFiltersModel {
  filterGeoRange: FilterDefinition;
  filterRoles: FilterDefinition;
  filterLocation: FilterDefinition;
  filterDateUpdated: FilterDefinition;
}
@Component({
  components: {
    FilterLocationCpt: () => Promise.resolve(FilterLocationCpt),
    FilterDatesCpt: () => Promise.resolve(FilterDatesCpt),
    FilterGeoRangeCpt: () => Promise.resolve(FilterGeoRangeCpt),
    FilterRoleCpt: () => Promise.resolve(FilterRoleCpt),
    FilterPolylineCpt: () => Promise.resolve(FilterPolylineCpt),
  },
})
export default class CompanySearchFilterBarCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) mobileDialog: boolean;
  @Prop({ default: () => null }) initFilters: IUIFilters;
  lookups: TurnstoneLookupsModel = new TurnstoneLookupsModel();
  profile = new ProfileModel();
  filters: IUIFilters = SearchService.defaultFilter();
  showFiltersMobile = false;
  showMapOnMobile = false;
  featureMapOnMobileEnabled = false;
  // self build filter disabled during the go live.
  isSelfBuildEnabled = false;

  visibleFilters: VisibleFiltersModel = {
    filterGeoRange: {
      key: "filterGeoRange",
      canBeHidden: false,
      visible: true,
      label: "Geolocation",
    },
    filterRoles: {
      key: "filterRoles",
      canBeHidden: false,
      visible: true,
      label: "Roles",
    },
    filterLocation: {
      key: "filterLocation",
      canBeHidden: false,
      visible: true,
      label: "Location",
    },
    filterDateUpdated: {
      key: "filterDateUpdated",
      canBeHidden: false,
      visible: true,
      label: "Date Updated",
    },
  };
  get roleTreeData() {
    if (!this.lookups) {
      return [];
    }
    if (!this.lookups.roleList) {
      return [];
    }
    return this.lookups.roleList.map((role) => {
      return {
        id: role.roleID,
        label: role.name,
        children: role.childRoles
          ? role.childRoles.map((x: any) => {
              return {
                id: x.roleID,
                label: x.name,
              };
            })
          : null,
      };
    });
  }
  get filtersMobileClass() {
    return this.$isMobile() ? "show-mobile" : "";
  }

  get IsSelfBuild() {
    const t = AccountService.getSubscriptionType();
    return t.IsSelfBuild === "True";
  }

  get IsMajorProjects() {
    const t = AccountService.getSubscriptionType();
    return t.IsMajorProjects === "True";
  }
  get isTableView() {
    return this.$route.path.indexOf("/t/") >= 0;
  }
  get currentViewId() {
    return this.isTableView ? 2 : 1;
  }

  get isDefaultView() {
    return this.profile.defaultView === this.currentViewId;
  }

  async setDefaultView() {
    const defaultViewId = (
      await AccountService.setDefaultView(
        this.profile.userProfileId,
        this.currentViewId,
      )
    ).data;
    this.profile.defaultView = defaultViewId;
    this.$eventHub.$emit("default_set", defaultViewId);
  }
  addFilter(filter: string) {
    const filterToAdd: any = this.visibleFilters[filter as keyof {}];
    filterToAdd.visible = true;
  }
  polylineUpdated(data: LatLng[]) {
    this.filters.polyline = data.map((x) => [x.lat, x.lng]) as number[][];
    this.filters.geoRange = {} as IGeoRange;
    this.filtersUpdated();
  }
  showMap() {
    const params: any = {
      projectLoaded: "100",
      clearCache: "0",
      reload: "false",
    };
    if (!!this.$route.params.filterId) {
      params.keepFilter = "1";
      params.filterId = this.$route.params?.filterId?.toString();
    }

    this.$router
      .push({
        name: "SearchCompanies",
        params,
      })
      .catch(() => {});
  }
  showTable() {
    const params: any = {
      projectLoaded: "100",
      clearCache: "0",
      reload: "false",
    };
    if (!!this.$route.params.filterId) {
      params.keepFilter = "1";
      params.filterId = this.$route.params?.filterId?.toString();
    }
    this.$router
      .push({
        name: "TableSearchCompanies",
        params: this.$route.params,
      })
      .catch(() => {});
  }

  toggleMap() {
    this.showMapOnMobile = !this.showMapOnMobile;
    this.$eventHub.$emit(events.ToggleMap, this.showMapOnMobile);
  }
  clearFilters() {
    this.filters = SearchService.defaultFilter();
    this.$eventHub.$emit(MapEvents.ClearDrawLayer);

    this.$eventHub.$emit(events.ClearFilters, SearchService.defaultFilter());
  }
  filtersUpdated() {
    this.$eventHub.$emit(events.FilterUpdated, this.filters);
    this.$emit("FilterUpdated", this.filters);

    this.showFiltersMobile = false;
  }
  setDefaultFilters(filters: IUIFilters) {
    this.filters = filters;
  }
  valueUpdated(min: string, max: string) {
    this.filters.value.min = min;
    this.filters.value.max = max;
    this.filtersUpdated();
  }
  geoRangeUpdated(geoRange: IGeoRange) {
    this.filters.geoRange = geoRange;
    this.filtersUpdated();
  }

  categoriesUpdated(selectedCategories: string[]) {
    this.filters.categories = selectedCategories;
    this.filtersUpdated();
  }
  projectTypesUpdated(selectedItems: string[]) {
    this.filters.projectTypes = selectedItems;
    this.filtersUpdated();
  }
  rolesUpdated(selectedItems: string[]) {
    this.filters.roles = selectedItems;
    this.filtersUpdated();
  }
  countiesUpdated(selectedItems: string[]) {
    this.filters.counties = selectedItems;
    this.filtersUpdated();
  }
  stagesUpdated(selectedStages: string[]) {
    this.filters.stage = selectedStages;
    this.filtersUpdated();
  }
  changeDateUpdate(selectedDates: DateRangeFilter) {
    this.filters.dateUpdated = selectedDates;
    this.filtersUpdated();
  }
  changeDateCreated(selectedDates: DateRangeFilter) {
    this.filters.dateCreated = selectedDates;
    this.filtersUpdated();
  }
  changeTenderDeadlineDate(selectedDates: DateRangeFilter) {
    this.filters.tenderDeadlineDate = selectedDates;
    this.filtersUpdated();
  }

  changeFinishDate(selectedDates: DateRangeFilter) {
    this.filters.finishDate = selectedDates;
    this.filtersUpdated();
  }
  changeDecisionDate(selectedDates: DateRangeFilter) {
    this.filters.decisionDate = selectedDates;
    this.filtersUpdated();
  }
  changeProjectStartDate(selectedDates: DateRangeFilter) {
    this.filters.projectStartDate = selectedDates;
    this.filtersUpdated();
  }
  changeApplicationDate(selectedDates: DateRangeFilter) {
    this.filters.applicationDate = selectedDates;
    this.filtersUpdated();
  }

  selfBuildUpdated(isSelfBuild: string) {
    this.filters.isSelfBuild = isSelfBuild;
    this.filtersUpdated();
  }

  async created() {
    this.$eventHub.$on(events.SetDefaultFilters, this.setDefaultFilters);
    this.lookups = await SearchService.getLookups();

    this.profile = await AccountService.getProfile();
  }
  destroyed() {
    this.$eventHub.$off(events.SetDefaultFilters, this.setDefaultFilters);
  }

  async mounted() {
    if (!!this.initFilters) {
      this.setDefaultFilters(this.initFilters);
    }
  }
}
