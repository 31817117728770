






































































import { ElTree } from "element-ui/types/tree";
import { Component, Prop, Watch } from "vue-property-decorator";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterRoleCpt extends FilterBase {
  @Prop({ default: [] }) model: string[];
  @Prop() roles: any[];
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;
  @Prop({ default: true }) showButton: boolean;
  selectedRoles: string[] = [];
  filterText: string = "";
  $refs: {
    tree1: HTMLFormElement;
    tree2: HTMLFormElement;
  };
  defaultProps = {
    children: "children",
    label: "label",
  };
  @Watch("filterText") filterTextUpdate(val: any) {
    this.$refs.tree2.filter(val);
  }
  @Watch("selectedRoles")
  onSelectedValuesChange() {
    if (!this.showButton) {
      this.$emit("updated", this.selectedRoles);
    }
  }
  filterNode(value: string, data: any) {
    if (!value) {
      return true;
    }
    return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }
  updateSelected() {
    this.selectedRoles = this.$refs.tree1.getCheckedKeys(true);
  }

  get treeData() {
    return this.roles;
  }

  mounted() {
    this.setupEvents("Roles");
    if (!this.showButton) {
      this.selectedRoles = this.model;
      this.$refs.tree1.setCheckedKeys(this.selectedRoles, true);
    }
  }

  destroyed() {
    this.destroyEvents();
  }

  toggleDialog() {
    this.toggle();
    this.selectedRoles = this.model;
    if (this.showButton) {
      this.$refs.tree2.setCheckedKeys(this.selectedRoles, true);
    } else {
      this.$refs.tree1.setCheckedKeys(this.selectedRoles, true);
    }
  }

  clear() {
    // The :key attribute in the el-checkbox-group is responsible of the refresh.
    // when the selectedStages.length changes. the checkbox group is re-rendered.
    // if you remove the :key attribute, the ui will be out of sync with the model
    this.selectedRoles.splice(0, this.selectedRoles.length);

    if (this.showButton) {
      this.$refs.tree2.setCheckedKeys(this.selectedRoles, true);
    } else {
      this.$refs.tree1.setCheckedKeys(this.selectedRoles, true);
    }
  }
  dismiss() {
    this.toggle();
  }
  applyFilter() {
    this.close();
    if (this.showButton) {
      this.selectedRoles = this.$refs.tree2.getCheckedKeys(true);
    } else {
      this.selectedRoles = this.$refs.tree1.getCheckedKeys(true);
    }
    this.trackEvent("FilterApplied", {
      category: "Search Results",
      label: this.filterName,
    });
    this.$emit("updated", this.selectedRoles);
  }

  get buttonText() {
    if (!this.model.length) {
      return "Roles";
    }
    if (this.model.length === 1) {
      return this.model[0];
    } else {
      return `${this.model.length} Roles`;
    }
  }

  get buttonType() {
    return this.model.length ? "primary" : "";
  }
}
