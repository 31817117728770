
























import { Component, Prop, Watch } from "vue-property-decorator";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterSelfBuildCpt extends FilterBase {
  @Prop() model: string;
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;

  internalModel: string = "";

  selfBuildValue = "SelfBuildOnly";
  largeScaleValue = "LargeScaleOnly";

  @Watch("internalModel")
  onInternalModelUpdated() {
    this.$emit("updated", this.internalModel);
  }

  created() {
    this.internalModel = this.model;
  }

  get buttonText() {
    if (this.model === this.selfBuildValue) {
      return "Self-build only";
    } else if (this.model === this.largeScaleValue) {
      return "Large scale only";
    } else {
      return "All projects";
    }
  }

  get buttonType() {
    if (this.model === this.selfBuildValue) {
      return "primary";
    } else if (this.model === this.largeScaleValue) {
      return "primary";
    } else {
      return "";
    }
  }
}
