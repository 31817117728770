

















































import { Component, Prop, Watch } from "vue-property-decorator";
// import AppVue from "@/AppVue.vue";
import _ from "lodash";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterCategoryCpt extends FilterBase {
  @Prop({ default: [] }) model: string[];
  @Prop() categories: any[];
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;

  $refs: {
    tree: HTMLFormElement;
  };

  selectedCategories: string[] = [];
  filterText: string = "";

  defaultProps = {
    children: "children",
    label: "label",
  };
  @Watch("filterText") filterTextUpdate(val: any) {
    this.$refs.tree.filter(val);
  }
  get categoryList() {
    return this.categories;
  }

  get sectors() {
    return [
      { id: 1001, key: 1, name: "Industrial", icon: "cis-icon-factory" },
      { id: 1002, key: 2, name: "Commercial", icon: "cis-icon-building" },
      { id: 1003, key: 3, name: "Residential", icon: "cis-icon-building" },
      { id: 1004, key: 4, name: "Education", icon: "el-icon-school" },
      { id: 1005, key: 5, name: "Medical", icon: "cis-icon-medical" },
      { id: 1006, key: 6, name: "Civil and Utilities", icon: "cis-icon-bus" },
      {
        id: 1007,
        key: 7,
        name: "Community and Sport",
        icon: "cis-icon-basketball",
      },
      { id: 1008, key: 8, name: "Hospitality", icon: "el-icon-food" },
      { id: 1009, key: 9, name: "Agriculture", icon: "cis-icon-grain" },
      {
        id: 1010,
        key: 10,
        name: "Design and Professional",
        icon: "cis-icon-paint",
      },
      {
        id: 1011,
        key: 11,
        name: "Construction Supplies",
        icon: "el-icon-truck",
      },
    ];
  }

  get treeData() {
    const data: any[] = [];
    if (this.categories) {
      this.sectors.forEach((sector) => {
        const filteredCats = this.categories.filter((c) => c.sectorID === sector.key);

        for (const cat of filteredCats) {
          cat.id = cat.categoryID;
          cat.label = cat.name;
        }

        data.push({
          id: sector.id,
          label: sector.name,
          children: filteredCats,
        });
      });
    }
    return data;
  }
  filterNode(value: string, data: any) {
    if (!value) {
      return true;
    }
    return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }
  created() {
    this.selectedCategories = this.model;
    this.setupEvents("Category");
  }
  destroyed() {
    this.destroyEvents();
  }
  dismiss() {
    this.toggle();
  }
  toggleDialog() {
    this.toggle();
    this.selectedCategories = this.model;
    this.$refs.tree.setCheckedKeys(this.selectedCategories, true);
  }

  clear() {
    this.selectedCategories.splice(0, this.selectedCategories.length);
    this.$refs.tree.setCheckedKeys(this.selectedCategories, true);
    this.applyFilter();
  }

  applyFilter() {
    this.close();
    this.selectedCategories = this.$refs.tree.getCheckedKeys(true);
    this.trackEvent("FilterApplied", { category: "Search Results", label: this.filterName });
    this.$emit("updated", this.selectedCategories);
  }
  get buttonText() {
    if (!this.model.length) {
      return "Category";
    }
    if (this.model.length === 1 && !!this.categoryList) {
      const selectedId = Number(this.model[0]);
      const cat = this.categoryList.find((x) => x.categoryID === selectedId);
      if (this.$isMobile()) {
        return `Category: ${cat.name}`;
      }
      return cat.name;
    } else {
      if (this.$isMobile()) {
        return `Categories (${this.model.length})`;
      }
      return `${this.model.length} Categories`;
    }
  }
  get buttonType() {
    return this.model.length ? "primary" : "";
  }
}
