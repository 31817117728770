import { render, staticRenderFns } from "./FilterAppealStatusCpt.vue?vue&type=template&id=51427821&scoped=true&"
import script from "./FilterAppealStatusCpt.vue?vue&type=script&lang=ts&"
export * from "./FilterAppealStatusCpt.vue?vue&type=script&lang=ts&"
import style0 from "./FilterAppealStatusCpt.vue?vue&type=style&index=0&id=51427821&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51427821",
  null
  
)

export default component.exports