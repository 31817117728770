












































































import { Component, Vue, Prop } from "vue-property-decorator";

import { SectorIconCpt, LabeledSectionCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";
import { roles as Roles, roleFlag } from "@/core/constants";
import { CompanySearchResultModel } from "@/core/models";

@Component({
  components: {
    LabeledSectionCpt,
    SectorIconCpt: () => Promise.resolve(SectorIconCpt),
  },
})
export default class CompanySearchResultDetailsCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) model: CompanySearchResultModel;
  roles = { icons: roleFlag, ...Roles };
  deduper = (names: string[]) => names.filter((v, i) => names.indexOf(v) === i);
  // deduping by role+name+surname because the ID are different
  get modelRoles() {
    const contactIdList = this.model.roles.map((x) => `${x.role}${x.surname}${x.forename}`).sort();
    const dedup = this.deduper(contactIdList);
    return dedup.map((x) => this.model.roles.find((y) => `${y.role}${y.surname}${y.forename}` === x));
  }
  filterByGroup(companyList: any[], group: string): any[] {
    return companyList.filter((x) => x.roleName === group);
  }

  handleClick() {
    this.$emit("close");
  }
  async created() {}
  async mounted() {}
}
