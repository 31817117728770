



























































import { Component, Prop } from "vue-property-decorator";
import FilterBase from "./FilterBase.vue";
import { MapEvents } from "@/modules/map/MapView.vue";
import { LatLng } from "leaflet";

@Component
export default class FilterGeoRangeCpt extends FilterBase {
  @Prop() range: string;
  @Prop() currentCenter: number[];
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;
  @Prop({ default: () => [] }) polyline: LatLng[];

  value = 0;
  Dublin = [53.3498, -6.2603];
  center = this.Dublin;
  valueRange = ["-", "1", "2", "5", "10", "20", "50", "100", "200", "300"];
  gettingLocation = false;

  get centerString() {
    return this.center.toString();
  }

  get selectedValue() {
    if (this.value === 0) {
      return "No filter";
    }
    return `${this.valueRange[this.value]} km`;
  }

  get visible() {
    return !this.polyline.length;
  }

  get applyDisabled() {
    return !this.center.length || !this.value;
  }

  get labelText() {
    if (this.value) {
      return this.selectedValue;
    }

    return "Select distance";
  }

  created() {
    this.setupEvents("Geo");
    this.$eventHub.$on(MapEvents.DrawCircle, this.drawCircle);
    this.$eventHub.$on(MapEvents.ClearCircleLayer, this.clearValuesOnly);
  }
  clearValuesOnly() {
    this.center = [];
    this.value = 0;
  }

  destroyed() {
    this.destroyEvents();
    this.$eventHub.$off(MapEvents.DrawCircle, this.drawCircle);
    this.$eventHub.$off(MapEvents.ClearCircleLayer, this.clearValuesOnly);
  }

  drawCircle(layer: any) {
    // this is to find the corrispondence between the range
    // manually selected
    // and the available options in the filter
    this.center = [layer._latlng.lat, layer._latlng.lng];
    const kmRadius = layer._mRadius / 1000;
    const vTop = this.valueRange.find((x) => {
      const asNumber = Number(x);
      return asNumber >= kmRadius;
    });
    const rv = [...this.valueRange].reverse();
    const vBottom = rv.filter((x) => {
      const asNumber = Number(x);
      return asNumber <= kmRadius;
    })[0];

    let v = "0";
    if (vTop === undefined) {
      if (vBottom === undefined) {
        v = "-";
      } else {
        v = vBottom;
      }
    } else {
      if (vBottom === undefined) {
        v = vTop;
      } else {
        const avg = (Number(vTop) + Number(vBottom)) / 2;
        v = kmRadius >= avg ? vTop : vBottom;
      }
    }
    this.value = this.valueRange.indexOf(v);
    this.apply();
  }
  refreshLocation() {
    if (!("geolocation" in navigator)) {
      this.center = this.Dublin;
      return;
    }
    this.gettingLocation = true;
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.center = [pos.coords.latitude, pos.coords.longitude];
      },
      (err) => {
        this.center = this.Dublin;
        this.gettingLocation = false;
      },
    );
  }

  toggleDialog() {
    this.toggle();
    this.value = this.valueRange.indexOf(this.range);
    // this.center = this.currentCenter;
    if (!this.center) {
      this.refreshLocation();
    }
  }

  sliderChanged() {}

  formatTooltip(val: number) {
    if (val === null) {
      return "";
    }
    return `${this.valueRange[val]}km`;
  }

  apply() {
    this.close();
    if (this.value === 0) {
      this.trackEvent("FilterApplied", { category: "Search Results", label: this.filterName, value: this.value });

      this.$emit("updated", {
        center: [],
        range: 0,
      });
    } else {
      this.trackEvent("FilterApplied", {
        category: "Search Results",
        label: this.filterName,
        value: this.valueRange[this.value],
      });
      this.$eventHub.$emit(MapEvents.DrawCircleFromUserInput, {
        center: this.center,
        radius: this.valueRange[this.value],
      });
      this.$emit("updated", {
        center: this.center,
        range: this.valueRange[this.value],
      });
    }
  }
  dismiss() {
    this.toggle();
  }
  clear() {
    this.center = [];
    this.value = 0;
    this.$eventHub.$emit(MapEvents.ClearDrawLayer);
    this.apply();
  }

  get buttonText() {
    return this.range && this.currentCenter ? `${this.range} km` : "";
  }

  get buttonType() {
    return this.currentCenter && this.currentCenter.length > 0 ? "primary" : "";
  }
}
