























import { Component, Prop, Watch } from "vue-property-decorator";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterSelfBuildCpt extends FilterBase {
  @Prop() model: string;
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;
  @Prop({ default: true }) showButton: boolean;

  internalModel: string = "";

  @Watch("internalModel")
  onInternalModelUpdated() {
    this.$emit("updated", this.internalModel);
  }

  created() {
    this.internalModel = this.model;
  }

  get buttonText() {
    return this.model;
  }

  get buttonType() {
    if (this.model !== undefined) {
      return "primary";
    } else {
      return "";
    }
  }
}
