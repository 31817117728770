



































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { filterEvents } from "./filterEvents";
import { toMoney } from "@/core/filters";
import FilterBase from "./FilterBase.vue";

@Component
export default class FilterValueCpt extends FilterBase {
  @Prop() min: string;
  @Prop() max: string;
  @Prop() className: string;
  @Prop({ default: false }) readonly: boolean;

  values = ["0", "€1,000"];
  valueMoney = [
    "",
    "1000",
    "10000",
    "50000",
    "100000",
    "250000",
    "500000",
    "750000",
    "1000000",
    "10000000",
    "50000000",
    "100000000",
    "",
  ];
  valueIndex = [0, this.valueMoney.length - 1];
  selectedMin = "0";
  selectedMax = "0";
  manualMaxValue = false;
  manualMinValue = false;
  $refs: any;
  created() {
    this.selectedMin = this.min;
    this.selectedMax = this.max;
    this.setupEvents("Values");
  }
  @Watch("min") handleMinUpdate() {
    this.$nextTick(() => {
      if (!!this.min) {
        const selectionIndex = this.valueMoney.indexOf(this.min);
        if (selectionIndex < 0) {
          // manual value
          this.selectedMin = this.min;
          return;
        }
        this.$set(this.valueIndex, 0, selectionIndex);
        this.$refs.slider.setValues();
        this.selectedMin = this.valueMoney[this.valueIndex[0]];
      }
    });
  }
  @Watch("max") handleMaxUpdate() {
    if (!!this.max) {
      const selectionIndex = this.valueMoney.indexOf(this.max);
      if (selectionIndex < 0) {
        // manual value
        this.selectedMax = this.max;
        return;
      }
      this.$set(this.valueIndex, 1, selectionIndex);
      this.$refs.slider.setValues();
      this.selectedMax = this.valueMoney[this.valueIndex[1]];
    }
  }

  get validMin() {
    return this.valueMoney.indexOf(this.selectedMin) >= 0;
  }
  get validMax() {
    return this.valueMoney.indexOf(this.selectedMax) >= 0;
  }
  reset() {
    this.manualMaxValue = false;
    this.manualMinValue = false;
    this.selectedMin = "";
    this.selectedMax = "";
    const selectionIndex = 0;
    this.$set(this.valueIndex, 0, selectionIndex);
    this.$set(this.valueIndex, 1, this.valueMoney.length - 1);
    this.$refs.slider.setValues();
  }
  inputMin() {
    this.manualMinValue = true;
  }
  inputMax() {
    this.manualMaxValue = true;
  }

  blur() {}

  destroyed() {
    this.destroyEvents();
  }

  toggleDialog() {
    // call the base toggle method
    this.toggle();

    // do the stuff related to this filter
    // this.selectedMin = this.valueMoney[this.valueIndex[0]];
    // this.selectedMax = this.valueMoney[this.valueIndex[1]];
  }

  toMoneyFormat(value: number) {
    if (!this.$options.filters) {
      return value;
    }
    return this.$options.filters["toCisValue"](value);
  }

  sliderChanged() {
    this.selectedMin = this.valueMoney[this.valueIndex[0]].toString();
    this.selectedMax = this.valueMoney[this.valueIndex[1]].toString();
  }

  formatTooltip(val: number) {
    if (val === null) {
      return "";
    }
    if (val === this.valueMoney.length - 1) {
      return "Max";
    }
    if (val === 0) {
      return "Min";
    }
    const money = this.valueMoney[val];
    if (!this.$options.filters) {
      return this.valueMoney[val].toString();
    }

    return this.$options.filters["toCisValue"](money);
  }
  dismiss() {
    this.toggle();
  }
  apply() {
    this.close();
    this.trackEvent("FilterApplied", {
      category: "Search Results",
      label: this.filterName,
    });

    this.$emit("updated", this.selectedMin, this.selectedMax);
  }

  clear() {
    this.selectedMin = "";
    this.selectedMax = "";
    this.$set(this.valueIndex, 0, 0);
    this.$set(this.valueIndex, 1, this.valueMoney.length - 1);
    this.$refs.slider.setValues();
    this.apply();
  }

  get buttonText() {
    if (this.max !== "" && this.min !== "") {
      return `${this.toMoneyFormat(
        parseInt(this.min, 10),
      )}-${this.toMoneyFormat(parseInt(this.max, 10))}`;
    }
    if (this.max !== "" && this.min === "") {
      return `< ${this.toMoneyFormat(parseInt(this.max, 10))}`;
    }
    if (this.max === "" && this.min !== "") {
      return `> ${this.toMoneyFormat(parseInt(this.min, 10))}`;
    }

    return "Value";
  }

  get buttonType() {
    return this.min !== "" || this.max !== "" ? "primary" : "";
  }
}
