
import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { filterEvents } from "./filterEvents";
import { filter } from "vue/types/umd";
@Component
export default class FilterBase extends AppVue {
  dialogVisible = false;
  dialogEnabled = true;
  filterName = "";
  placement = "bottom-start";
  handleFilterDialogOpened = (eventFilterName: string) => {};

  handleFilterDialogClosed = (eventFilterName: string) => {};

  setupEvents(filterName: string) {
    this.filterName = filterName;
    // factory required because in the handler this.filtername would be undefined
    this.handleFilterDialogOpened = this.filterDialogOpenedFactory(filterName);
    this.handleFilterDialogClosed = this.filterDialogClosedFactory(filterName);

    this.$eventHub.$on(
      filterEvents.FilterDialogOpened,
      this.handleFilterDialogOpened,
    );
    this.$eventHub.$on(
      filterEvents.FilterDialogClosed,
      this.handleFilterDialogClosed,
    );
  }

  destroyEvents() {
    this.$eventHub.$off(
      filterEvents.FilterDialogOpened,
      this.handleFilterDialogOpened,
    );
    this.$eventHub.$off(
      filterEvents.FilterDialogClosed,
      this.handleFilterDialogClosed,
    );
  }

  toggle() {
    this.dialogVisible = !this.dialogVisible;
    if (this.dialogVisible) {
      this.$eventHub.$emit(filterEvents.FilterDialogOpened, this.filterName);
    } else {
      this.$eventHub.$emit(filterEvents.FilterDialogClosed, this.filterName);
    }
  }
  mounted() {
    if (this.$isMobile()) {
      this.placement = "top";
    }
  }

  close() {
    this.dialogVisible = false;
    this.$eventHub.$emit(filterEvents.FilterDialogClosed, this.filterName);
  }

  private filterDialogClosedFactory(filterName: string) {
    return (eventFilterName: string) => {
      this.dialogEnabled = true;
    };
  }
  private filterDialogOpenedFactory(filterName: string) {
    return (eventFilterName: string) => {
      if (eventFilterName !== "none" && filterName !== eventFilterName) {
        this.dialogEnabled = false;
        this.dialogVisible = false;
      }
    };
  }
}
