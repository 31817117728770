



































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import FilterBase from "./FilterBase.vue";
import { MapEvents } from "@/modules/map/MapView.vue";
import { LatLng } from "leaflet";

export class Type {}

@Component({
  components: {},
})
export default class FilterPolylineCpt extends FilterBase {
  @Prop() className: string;
  @Prop({ default: () => [] }) model: LatLng[];
  @Prop({ default: false }) readonly: boolean;
  points: LatLng[] = [];
  @Watch("model")
  updateModel(newValue: LatLng[]) {
    if (this.points.length === 0) {
      // possible polyline to draw on the screen.
      if (newValue.length > 1) {
        this.points = newValue;
      }
    } else {
      this.points = newValue;
    }
  }

  get visible() {
    return this.$isMobile() || this.points.length > 0 || this.model.length > 0;
  }

  get popupText() {
    return this.points.length > 0 ? `Area with ${this.points.length} points drawn on map` : "";
  }

  get buttonText() {
    return this.points.length > 0 ? `${this.points.length} points` : "";
  }

  get buttonType() {
    return this.points.length > 0 ? "primary" : "";
  }
  async created() {
    this.setupEvents("Polyline");
    this.$eventHub.$on(MapEvents.DrawPolyline, this.drawPolyline);
    this.$eventHub.$on(MapEvents.DrawRectangle, this.drawRectangle);
  }

  toggleDialog() {
    this.toggle();
  }

  destroyed() {
    this.destroyEvents();
    this.$eventHub.$off(MapEvents.DrawPolyline, this.drawPolyline);
    this.$eventHub.$off(MapEvents.DrawRectangle, this.drawRectangle);
  }

  drawPolyline(event: any) {
    this.points = event.getLatLngs() as LatLng[];
    this.apply();
  }

  drawRectangle(event: any) {
    // I have to reverse the points coming from the rectangle because
    // they are ordered in the wrong way.
    // CW? CCW? I have no idea. The normal polyline doesn't need it.
    this.points = event.getLatLngs()[0].reverse() as LatLng[];
    this.apply();
  }
  clear() {
    this.points = [];
    this.$eventHub.$emit(MapEvents.ClearDrawLayer);
    this.apply();
  }
  dismiss() {
    this.toggle();
  }
  apply() {
    this.close();
    this.$emit("updated", this.points);
  }

  async mounted() {}
}
